import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import WorkerDocument from './WorkerDocument';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import 'bootstrap';
import 'jquery';
import 'popper.js';

export default function App() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/:workerId" element={<WorkerDocument />} />
                    <Route path='*' element={
                        <>
                            <h1 className='pt-5 text-center'>404</h1>
                            <p className='text-center'>Diese Seite wurde nicht gefunden!</p>
                        </>
                    } />
                </Routes>
            </BrowserRouter>
        </>
    )
}
