import React, { Component } from 'react'
import { useParams } from "react-router-dom";

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}


class WorkerDocument extends Component {

  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      workerId: '',
      error: [],
      docUrl: ''
    }
  }

  existsWorkerDocument = async (workerId) => {
    var requestURL = process.env.REACT_APP_API_BASE_URL
      + `/api/v1/getWorkerDocument/${workerId}`;
    var res = await fetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    var data = await res.json();
    if (data.failed == true) {
      return false;
    } else {
      return true;
    }
  }

  async componentDidMount() {
    this.setState({ workerId: this.props.params.workerId });
    var control_worker_document = await this.existsWorkerDocument(this.props.params.workerId);
    if (!control_worker_document) {
      this.setState({ error: { error: true, message: 'Dieser Mitarbeiter Dokument existiert nicht!' } })
    } else {

    }
  }



  render() {
    return (
      <>
        {this.state.error.error == true ? (
          <>
            <div className='p-5 alert alert-danger bg-danger text-white' style={{ border: 'none' }}>
              <h1 style={{ fontWeight: 'bold' }}>Fehler</h1>
              <p>{this.state.error.message}</p>
            </div>
          </>
        ) : null}
        <div className='container' style={{ paddingTop: '15vh' }}>
          {(this.state.step == 0 && this.state.error.length == 0) ? (
            <>
              <div className='row'>
                <div className='col-md-6 mx-auto'>
                  <h3 className='font-weight-bold text-center pt-2 pb-2'>
                    Mitarbeter-Dokument
                  </h3>
                  <div className='infobox'>
                    <ul>
                      <li><span className='font-weight-bold'>Hinweis:</span><br />
                        Hinweis: Bitte beachten Sie, dass Popup-Fenster in Ihren Browsereinstellungen zugelassen sein müssen. Falls dies nicht der Fall ist, aktivieren Sie bitte diese Funktion über die 'Einstellungen'.
                      </li>
                    </ul>
                  </div>
                  <form method="POST" onSubmit={async (e) => {
                    e.preventDefault();
                    var docResponse = await fetch(process.env.REACT_APP_API_BASE_URL + '/api/v1/getWorkerDocument', {
                      method: 'POST',
                      headers: {
                        'Accept': '*/*',
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({
                        id: this.state.workerId,
                        password: e.target.password.value
                      })
                    });
                    var data = await docResponse.json();
                    if (data.failed == true) {
                      alert("Das eingegebene Passwort für das Mitarbeiter Dokument ist falsch!");
                    } else {
                      if (data.document.fileBuffer != null) {
                        var arrayBuffer = new Uint8Array(data.document.fileBuffer.data);
                        var file = new Blob([arrayBuffer], { type: 'application/pdf' });
                        var fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                        URL.revokeObjectURL(fileURL);

                        e.target.password.value = '';
                      } else {
                        alert("Es wurde kein Mitarbeiter Dokument gefunden!");
                      }
                    }
                  }}>
                    <div className='form-group'>
                      <input required autoComplete='off' className='wd-pw-field form-control' placeholder='Passwort' name='password' type='password' />
                    </div>
                    <input type='submit' value='Einsehen' className='w-100 btn mt-2 text-white' style={{ backgroundColor: 'black' }} />
                  </form>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </>
    )
  }
}


export default withParams(WorkerDocument);